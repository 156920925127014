<template>
  <v-dialog
    v-model="isMainBioDialogOpen"
    max-width="70vw"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <!-- :fullscreen="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smAndDown" -->
    <v-stepper
      v-model="activeStep"
      vertical
      class="custom-header"
    >
      <v-toolbar
        dark
        color="primary"
        dense
        outlined
        flat
      >
        <v-btn
          icon
          dark
          @click="closeDialogBox"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>

        <v-toolbar-title>
          <span class="title">{{ this.visitStore.patientData.name }}</span>
        </v-toolbar-title>
      </v-toolbar>
      <!-- Step 1 -->
      <v-stepper-step
        :complete="activeStep > 1"
        step="1"
      >
        <div class="d-flex align-center">
          <span class="text--primary text-4xl font-weight-bold me-3">01</span>
          <div class="d-flex flex-column">
            <span class="text--primary text-sm font-weight-semibold">Your Details</span>
            <span class="text--secondary text-xs">Update your information</span>
          </div>
        </div>
      </v-stepper-step>

      <v-stepper-content step="1">
        <basic-profile
          :is-bio-dialog-open="isBioDialogOpen"
          @next="next($event)"
        ></basic-profile>
        <!-- <stepper-buttons
          :step="activeStep"
          :step-count="3"
          @previous="previous($event)"
          @next="next($event)"
        ></stepper-buttons> -->
      </v-stepper-content>

      <!-- Step 2 -->
      <v-stepper-step
        :complete="activeStep > 2"
        step="2"
      >
        <div class="d-flex align-center">
          <span class="text--primary text-4xl font-weight-bold me-3">02</span>
          <div class="d-flex flex-column">
            <span class="text--primary text-sm font-weight-semibold">Medical Info</span>
            <span class="text--secondary text-xs">Brief Medical Questions</span>
          </div>
        </div>
      </v-stepper-step>

      <v-stepper-content step="2">
        <medical-history
          :is-bio-dialog-open="isBioDialogOpen"
          @next="next($event)"
          @previous="previous($event)"
        ></medical-history>
        <!-- <stepper-buttons
          :step="activeStep"
          :step-count="3"
          @previous="previous($event)"
          @next="next($event)"
        ></stepper-buttons> -->
      </v-stepper-content>

      <!-- Step 3 -->
      <v-stepper-step
        :complete="activeStep > 3"
        step="3"
      >
        <div class="d-flex align-center">
          <span class="text--primary text-4xl font-weight-bold me-3">03</span>
          <div class="d-flex flex-column">
            <span class="text--primary text-sm font-weight-semibold">Insurance</span>
            <span class="text--secondary text-xs">Tell us about your insurance</span>
          </div>
        </div>
      </v-stepper-step>

      <v-stepper-content
        id="insranceContent"
        step="3"
      >
        <insurance-info
          :is-bio-dialog-open="isBioDialogOpen"
          @previous="previous($event)"
          @closeDialog="closeDialog($event)"
        ></insurance-info>

        <!-- <stepper-buttons
          :step="activeStep"
          :step-count="3"
          @previous="previous($event)"
          @next="next($event)"
          @closeDialog="closeDialog($event)"
        ></stepper-buttons> -->
      </v-stepper-content>
    </v-stepper>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import basicProfile from '@/components/Forms/basicProfile.vue'
import insuranceInfo from '@/components/Forms/insuranceInfo.vue'
import { visitService } from '@/api/index.js'
import { useVisitStore } from '@/stores/Visit.js'
import { splitFullName } from '@/util/functions/index.js'
import stepperButtons from '@/components/Forms/stepperButtons.vue'
import medicalHistory from '@/components/Forms/medicalHistory.vue'

export default {
    name: 'CreateProfileForm',
    components: {
        basicProfile, insuranceInfo, stepperButtons, medicalHistory
    },
    props: {
        isMainBioDialogOpen: {
            type: Boolean,

            default: true,
        },
    },
    setup() {
        const visitStore = useVisitStore()

        return { visitStore }
    },
    data() {
        return {
            activeStep: 1, // FIXME swithc back to 1
            icons: { mdiClose },
        }
    },
    computed: {
        isBioDialogOpen(val) {
            if (this.activeStep === 1) {
                return true
            }
            if (val) {
                return false
            }

            return false
        },
        patient() {
            const splitName = splitFullName(this.visitStore.patientData.name)

            return splitName
        },
        patientData() {
            return this.visitStore.patientData
        },
    },
    mounted() {
    },
    methods: {
        async updateVisitStatus(data) {
            const statusData = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                visitId: this.visitStore.visitId,
                patientId: this.visitStore.patientData.id,
                basic: data.basic || 'pending',

                // processing: data.processing || 'pending',
                // payment: data.payment || 'pending',
                // delivery: data.delivery || 'pending',
            }
            await visitService.updateVisitStatus(statusData)
            await this.visitStore.setVisit({ id: statusData.visitId, dob: statusData.dateofBirth })
        },
        closeDialogBox(event) {
            this.$emit('updateMainBioDialog', false)
        },
        async closeDialog() {
            await this.visitStore.setVisit({ id: this.visitStore.visitId, dob: this.visitStore.patientData.dateofBirth })
            if (this.visitStore.patientData.id === this.visitStore.visitData.patientId) {
                // this.isMainBioDialogOpen = false
                if (this.visitStore.patientData?.detailedAddress && (this.visitStore.patientData?.hasAllergy === 0 || this.visitStore.patientData?.hasAllergy === 1) && (this.visitStore.patientData?.takesMeds === 0 || this.visitStore.patientData?.takesMeds === 1) && (this.visitStore.patientData?.insuranceStatus === 0 || this.visitStore.patientData?.insuranceStatus === 1)) {
                    const data = {
                        dateofBirth: this.visitStore.patientData.dateofBirth, // need to update this
                        visitId: this.visitStore.visitId, // need to update this
                        patientId: this.visitStore.patientData.id, // need to update this
                        basic: 'complete',

                        // processing: 'inprogress',
                        // payment: 'pending',
                        // delivery: 'pending',
                    }
                    this.updateVisitStatus(data)
                } else {
                    const data = {
                        dateofBirth: this.visitStore.patientData.dateofBirth, // need to update this
                        visitId: this.visitStore.visitId, // need to update this
                        patientId: this.visitStore.patientData.id, // need to update this
                        basic: 'pending',

                        // processing: 'pending',
                        // payment: 'pending',
                        // delivery: 'pending',
                    }
                    this.updateVisitStatus(data)
                }
            }
            this.$emit('updateMainBioDialog', false)
        },
        previous(step) {
            this.activeStep = step
        },
        next(step) {
            this.activeStep = step
        },
    },
}
</script>

<style lang="scss" scoped>
#insranceContent {

    margin: -8px -28px -16px -4px;
}
</style>
