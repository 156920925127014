<template>
  <div class="d-flex justify-center align-center">
    <div class="d-flex justify-space-between">
      <div v-if="previousActive">
        <v-btn
          vue-tour-target="previousButton"
          outlined
          class="me-2"
          @click="previous"
        >
          Previous
        </v-btn>
      </div>
      <!-- <div v-if="nextActive">
        <v-btn
          :disabled="disabled"
          vue-tour-target="nextButton"
          color="primary"
          class="me-2"
          @click="saveAddress"
        >
          Save Address
        </v-btn>
      </div> -->
      <div v-if="nextActive">
        <v-btn
          :disabled="disabled"
          vue-tour-target="nextButton"
          color="primary"
          @click="next"
        >
          Next
        </v-btn>
      </div>
      <div v-if="step===stepCount">
        <v-btn

          color="secondary"
          @click.prevent="closeDialog"
        >
          Close
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { useVisitStore } from '@/stores/Visit.js'

export default {
    name: 'StepperButtons',
    components: {
    },
    props: {
        step: {
            type: Number,
            required: true,
            default: 1,
        },
        stepCount: {
            type: Number,
            required: false,
            default: 3,
        },
        disabledButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        formData: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    setup() {
        const visitStore = useVisitStore()

        return {
            visitStore
        }
    },

    data() {
        return {
            // Button Conditionals
            disabled: false,

            // panels & steps
            activeStep: 1, // 1 default

            // icons
            icons: {},
        }
    },
    computed: {
        previousActive() {
            if (this.activeStep === 1) {
                return false
            }

            return true
        },
        nextActive() {
            if (this.activeStep === this.stepCount) {
                return false
            }

            return true
        },
    },
    watch: {
        step(val) {
            this.activeStep = val
        },
    },
    mounted() {
        this.$root.$on('Refresh_Table', () => {})
    },
    methods: {
        saveAddress() {
            this.$emit('saveAddress')
        },
        closeDialog() {
            this.$emit('closeDialog', false)
        },
        previous() {
            if (this.activeStep === 1) {
                this.activeStep = 1
            }
            this.activeStep -= 1

            /**
             * Previous Button to change activeStep to previous step
             *
             * @property {number} activeStep Emits the active Stepper id
             */
            this.$emit('previous', this.activeStep)
        },
        next() {
            if (this.activeStep === this.stepCount) {
                this.activeStep = this.stepCount
            }
            this.activeStep += 1

            /**
             * Next Button to change activeStep to next step
             *
             * @property {number} activeStep Emits the active Stepper id
             */
            this.$emit('next', this.activeStep)
        },
    },
}
</script>
